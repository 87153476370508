<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">담당자 상세 정보</h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle>
          <template v-slot:body>
            <tr>
              <th>ID<span class="ico_purchase ico_star">필수항목</span></th>
              <td colspan="3">
                {{ itemId }}
              </td>
            </tr>
            <tr>
              <th>권한<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <template v-if="authorityState.currentStatus === 'SUPER'"> 슈퍼관리자 </template>
                <div v-else class="group_form">
                  <Radio
                    :name="authorityState.name"
                    :dataList="authorityState.dataList"
                    :selectedId.sync="authorityState.currentStatus"
                  />
                </div>
              </td>
              <th>계정상태<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <div class="group_form">
                  <Radio
                    :name="acountState.name"
                    :dataList="acountState.dataList"
                    :selectedId.sync="acountState.currentStatus"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>이메일 수신여부<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <div class="group_form">
                  <Radio
                    :name="emailState.name"
                    :dataList="emailState.dataList"
                    :selectedId.sync="emailState.currentStatus"
                  />
                </div>
              </td>
              <th>알림 수신여부<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <div class="group_form">
                  <Radio
                    :name="alarmState.name"
                    :dataList="alarmState.dataList"
                    :selectedId.sync="alarmState.currentStatus"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>담당자명<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                {{ name }}
              </td>
              <th>부서명&#47;직급<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Input :value.sync="jobgrade" />
              </td>
            </tr>
            <tr>
              <th>담당 업무<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <Input :value.sync="job" />
              </td>
              <th>이메일<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <InputEmail :emailName.sync="email.name" :emailDomain.sync="email.domain" />
              </td>
            </tr>
            <tr>
              <th>직통번호<span class="ico_purchase ico_star">필수항목</span></th>
              <td>
                <InputPhone
                  :useMobile="true"
                  :useArea="true"
                  :phone0.sync="tel.phone0"
                  :phone1.sync="tel.phone1"
                  :phone2.sync="tel.phone2"
                />
              </td>
              <th>핸드폰번호</th>
              <td>
                <InputPhone
                  :useMobile="true"
                  :useArea="false"
                  :phone0.sync="mobile.phone0"
                  :phone1.sync="mobile.phone1"
                  :phone2.sync="mobile.phone2"
                />
              </td>
            </tr>
            <tr>
              <th>계정 등록일</th>
              <td>{{ regDate }}</td>
              <th>최근 접속일</th>
              <td>{{ lastDate }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <!-- <button type="button" class="btn_tertiary btn_large">비밀번호 초기화</button> -->
        <button
          v-if="!isError"
          type="button"
          class="btn_secondary btn_large"
          @click="onClickRemove"
        >
          삭제
        </button>
        <button v-if="!isError" type="submit" class="btn_primary btn_large" @click="onClickSave">
          저장
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isRemoveConfirm"
        alertText="담당자를 삭제하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelAlert"
        @onClickPrimary="remove"
      />
      <!-- <AlertPopup
        v-if="isPasswordReset"
        alertText="비밀번호를 초기화 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelAlert"
        @onClickPrimary="remove"
      /> -->
      <AlertPopup
        v-if="isPopAlertRemoved"
        alertText="삭제 되었습니다."
        btnPrimaryText="확인"
        @onClickPrimary="onClickPopRemoved"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import InputEmail from "@/components/common/input/InputEmail";
import InputPhone from "@/components/common/input/InputPhone";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import { getDateString } from "@/utils/dateUtils";
import DirectorWriteMixin from "./DirectorWriteMixin";
import { setPhone } from "@/utils/phoneUtils";
import { setEmail } from "@/utils/emailUtils";

import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "DirectorModify",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    Input,
    Radio,
    InputEmail,
    InputPhone,
    Sticky,
    AlertPopup,
  },
  mixins: [DirectorWriteMixin, PageMixin],
  data() {
    return {
      managerNum: "",
      isError: false,
      isPopAlertRemoved: false,
      isRemoveConfirm: false,
      isPasswordReset: false,
    };
  },
  mounted() {
    this.managerNum = this.$route.params.id;
    this.getItem(this.managerNum);
  },
  methods: {
    async getItem(id) {
      const result = await ApiService.shared.getData(`${this.$apiPath.MANAGER}/${id}`);
      const resultData = result.data;
      if (!resultData) {
        this.isError = true;

        this.alert(result.text);
        return;
      }

      this.itemData = resultData;

      this.itemId = resultData.loginId;
      this.acountState.currentStatus = resultData.status;
      this.name = resultData.name;
      this.job = resultData.job;
      this.jobgrade = resultData.jobgrade;

      if (resultData.role) {
        this.authorityState.currentStatus = resultData.role;
      }

      if (String(resultData.emailYn)) {
        this.emailState.currentStatus = resultData.emailYn;
      }

      if (String(resultData.alarmYn)) {
        this.alarmState.currentStatus = resultData.alarmYn;
      }

      if (resultData.email) {
        setEmail(resultData.email, this.email);
      }

      if (resultData.tel) {
        setPhone(resultData.tel, this.tel);
      }
      if (resultData.mobile) {
        setPhone(resultData.mobile, this.mobile);
      }

      if (resultData.regDate) {
        this.regDate = getDateString(resultData.regDate);
      }
      if (resultData.lastDate) {
        this.lastDate = getDateString(resultData.lastDate);
      }
    },
    onClickCancelAlert() {
      this.isRemoveConfirm = false;
      this.isPasswordReset = false;
    },

    onClickSave() {
      const alertMess = this.getAlertMessage();

      if (alertMess) {
        this.alert(alertMess);
        return;
      }

      const isModifyMode = true;
      const path = `${this.$apiPath.MANAGER}/${this.managerNum}`;

      this.save(isModifyMode, path);
    },
    onClickRemove() {
      this.isRemoveConfirm = true;
    },
    async remove() {
      const path = `${this.$apiPath.MANAGER}/${this.managerNum}`;
      const result = await ApiService.shared.deleteData(path);

      if (result.code === "200") {
        this.isPopAlertRemoved = true;

        // this.alert( '삭제 되었습니다.' );
      } else {
        this.alert(result.text);
      }
    },
    onClickPopRemoved() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_DIRECTOR_LIST,
      });
    },
    getAlertMessage() {
      const result = this.getAlertMessageCommon();

      return result;
    },
    onCompleteSave(obj) {
      const userData = LocalStorageManager.shared.getUserData();

      if (!userData) return;
      // 자기 자신 수정할 경우에만.
      if (userData.managerNum !== obj.managerNum) return;

      let objNew = {
        email: obj.email,
        tel: obj.tel,
      };

      LocalStorageManager.shared.assignUserData(objNew);
    },
  },
};
</script>
